






































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { EFileFormat } from '@/models'
import GroupChatService from '@/services/GroupChatService'
import CheckFileFormat from '@/helpers/CheckFileExtension'
import FormatDate from '@/helpers/FormatDate'
import CheckDevice from '@/helpers/CheckDevice'
import { EhumbNailsDisplay } from '@/models/Setting/Enum'
import PreviewText from '@/helpers/PreviewText'

@Component
export default class GalleryModal extends Vue {
  @Prop() private idGroupChat: any
  private galleries: any[] = []
  private isLoading: boolean = true
  private file_name_download: string = ''
  private eFileFormat = EFileFormat
  private modalMess: string = ''
  private avatarDefault = require('@/assets/images/avatar-default.png')
  private page: number = 1
  private maxPage: number = 1
  private limit: number = this.$store.state.setting.setting.setting_display
    .number_item_lists
  private displayThumbnail: boolean =
    this.$store.state.setting.setting.setting_display.display_image_video ===
    EhumbNailsDisplay.DISPLAY_THUMBNAILS

  @Watch('idGroupChat')
  watchRouteChange() {
    this.getGalleryByGroup()
  }

  created() {
    this.getGalleryByGroup()
  }

  async getGalleryByGroup(loadMore?: boolean) {
    await GroupChatService.getGalleryByGroup(
      this.idGroupChat,
      this.page,
      this.limit
    )
      .then(res => {
        if (res.status === 200) {
          if (loadMore) {
            this.galleries = [...this.galleries, ...res.data.data]
          } else {
            this.galleries = res.data.data
          }
          this.maxPage = res.data.last_page
        }
      })
      .catch(err => {
        console.log(err)
      })
    this.isLoading = false
  }

  loadMore() {
    ++this.page
    this.getGalleryByGroup(true)
  }

  formatDate(date: string) {
    return FormatDate.formatCompact(date)
  }

  getFileFormat(extension: string) {
    return CheckFileFormat.getFileFormat(extension)
  }

  /**
   * Open delete confirm modal
   */
  openModalConfirmDelete(gallery: any) {
    this.modalMess = this.$t('common.message.delete') as string
    this.$bvModal.show(`modal-confirm-chat-gallery-${gallery.id}`)
  }

  /**
   * Handle unknown array
   */
  hanldeUnknownArray(anyArray: any[]): any[] {
    return anyArray
  }

  /**
   * Delete gallery
   */
  deleteGallery(gallerySelected: any) {
    let msgIdHasGalleryDelete = gallerySelected.id
    let groupChatId = gallerySelected.group_chat_id

    GroupChatService.deleteGalleryByGroup(groupChatId, msgIdHasGalleryDelete)
      .then(res => {
        if (res.status === 200) {
          this.galleries = this.galleries.filter(
            (item: any) => item.id !== msgIdHasGalleryDelete
          )
          this.modalMess = this.$t('common.message.deleted_attach_file', {
            from: `No.${gallerySelected.no_in_group_chat}`
          }) as string
          this.$bvModal.show('modal-success-chat-gallery')
        }
      })
      .catch(err => {
        this.modalMess = this.$t('common.message.delete_fail_attach_file', {
          from: `No.${gallerySelected.no_in_group_chat}`
        }) as string

        this.$bvModal.show('modal-error-chat-gallery')
      })
  }

  downloadFile(file: any) {
    this.file_name_download = file.file_name
    let fileIds = {
      file_ids: [file.id]
    }

    //handle dowload IOS not safari
    if (
      CheckDevice.iOSBrowerName().toLowerCase() !== CheckDevice.safari &&
      CheckDevice.isMobile() &&
      CheckDevice.getMobileOS() === CheckDevice.ios
    ) {
      window.open(file.path)
      return
    }

    this.$blockui.show()
    GroupChatService.downloadGallery(fileIds)
      .then(res => {
        if (res.status === 200) {
          this.hanldeDownloadFile(res)
        }
      })
      .catch(err => console.log(err))
      .finally(() => this.$blockui.hide())
  }

  hanldeDownloadFile(res: any) {
    const bytes = new Uint8Array(res.data)
    const blob = new Blob([bytes], { type: '*' })
    let a = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.setAttribute('download', this.file_name_download)
    a.click()
  }

  getFormatIconClass(extension: string) {
    return CheckFileFormat.getIconClassNameWithFileExtention(extension)
  }

  handleLongText(text: string, max?: number) {
    return PreviewText.covertToPreviewText(text, max)
  }
}
