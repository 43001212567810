




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { groupchatRef, messagesRef } from "@/firebase";
import {
  GroupChatModel,
  MessageModel,
  UserModel,
  FileDetail,
  MessageReply,
  UserGroupChat,
  EUserStatus,
  IdName,
  EFileFormat,
} from "@/models";
//@ts-ignore
import _ from "lodash";
import { triggerFavorite } from "@/helpers/GetNotificationCount";
import GroupChatService from "@/services/GroupChatService";
import MessageService from "@/services/MessageService";
import moment from "moment";
import ManageMemberGroupChat from "@/views/Chat/ManageMemberGroupChat/index.vue";
import GalleryModal from "@/views/Chat/GalleryModal/index.vue";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import ModalShowProfile from "@/components/Modal/ModalShowProfile.vue";
import ChatService from "@/services/ChatService";
import CommonTopicVueX from "@/store/modules/CommonTopic";
import CheckDevice from "@/helpers/CheckDevice";
import ModalLikers, { ListLiker } from "@/components/Modal/ModalLikers.vue";
import { checkScript } from "@/validations/validation";
const CommonTopicModule = getModule(CommonTopicVueX, store);
import NavMenu from "@/components/NavMenu.vue";
import Header from "@/components/Header.vue";
import Banner from "@/components/Banner.vue";
import { EhumbNailsDisplay } from "@/models/Setting/Enum";
import CheckFileFormat from "@/helpers/CheckFileExtension";
import PreviewText from "@/helpers/PreviewText";

@Component({
  components: {
    ManageMemberGroupChat,
    GalleryModal,
    ModalShowProfile,
    ModalLikers,
    NavMenu,
    Header,
    Banner,
  },
})
export default class Chat extends Vue {
  private userId: number = -1;
  private currentUser: UserModel = new UserModel();
  private idLogin?: number;
  private groupChatSelected: GroupChatModel = new GroupChatModel();
  private unreadMeesageStartId: number = 0;
  private unreadMeesageEndId: number = 0;
  private groupItemSelected?: number;
  private groupSelected?: IdName;
  private lstMessageGroupChatDefault: any[] = [];
  private lstMessageGroupChat: MessageModel[] = [];
  private fileUpload: any = null;
  private fileUploadTemp: any = null;
  private messageReply: MessageModel = new MessageModel();
  private lstFileUpload: any[] = [];
  private isVisableReplyMessage: boolean = false;
  private isVisiableLstUploadFile: boolean = false;
  private loadingLstMessage: boolean = true;
  private showNoMessage: boolean = false;
  private getTinyConfig: any = {};
  private getKey: string = "";
  private editorData: string = "";
  private messageSpecial: boolean = false;
  private openGallery: boolean = false;
  private showModalManageMember: boolean = false;
  private infoMess: string = "";

  //API hepler
  private page: number = 1;
  private lastPage: number = 1;
  private searchPage: number = 1;
  private pageUp: number = 1;
  private pageDown: number = 1;
  private isPageUp: boolean = false;
  private isPageDown: boolean = false;
  private loadMoreDown: boolean = false;
  private disablePageUp: boolean = false;
  private disablePageDown: boolean = false;
  private limitMessage: number = 10; //this.$store.state.setting.setting.setting_display.number_item_lists

  //
  private checkUserSendMessage: boolean = false;
  private checkFirstScrollBottom: boolean = true;
  private idGroupChat: number = Number(this.$route.params.groupChatId);
  private groupChatName: string = "";
  private eUserStatus = EUserStatus;
  private contentTextArea: string = "";
  private textAreaRows: number = 1;
  private chatBoxNameColor: string =
    this.$store.state.setting.setting.setting_themes.theme.title_color;
  //validate
  private imageType: string[] = [
    "image/png",
    "image/gif",
    "image/jpeg",
    "image/jpg",
  ];
  private maxFileSize: any = {
    mb: 25,
    bytes: 25 * 1048576,
  };
  private groupChatOver200: boolean = false;
  private overLengthContent: boolean = false;
  private includesScriptTagContent: boolean = false;
  private showFooter: boolean = true;
  private isMobile: boolean = false;
  private isSafariIos: boolean = false;
  private screenLoading: boolean = true;
  private firstLoad: boolean = true;
  private showButtonScrollToBottom: boolean = false;

  //
  private fileIds: number[] = [];
  private file: FileDetail = new FileDetail();
  private file_name_download: string = "";
  private LEAVE_GROUP_CHAT: string = "LEAVE_GROUP_CHAT";
  private LEAVE_SYSTEM: string = "LEAVE_SYSTEM";
  private bannerShowOff: boolean = false;

  //modal helper
  private modalMess: string = "";
  private modalAction: any = () => {};
  private listDeletedMessage: any[] = [];
  private isOverLength: boolean = false;
  private isIncludesScriptTag: boolean = false;
  private includesScriptTagMess: string = this.$t("common.form.no_script_tag", {
    name: this.$t("common.form.document") as string,
  }) as string;
  private overLengthMess: string = this.$t("common.form.max_length", {
    name: this.$t("common.form.document") as string,
    length: 20000,
  }) as string;
  private avatarDefault = require("@/assets/images/avatar-default.png");
  private deleteMess: any;
  private blinkMessageId: number = -1;
  private maxLikerDisplay: number = 10;
  private likerListModal: ListLiker[] = [];
  private confirmChange: any = () => {};
  private displayThumbnail: boolean =
    this.$store.state.setting.setting.setting_display.display_image_video ===
    EhumbNailsDisplay.DISPLAY_THUMBNAILS;
  private eFileFormat = EFileFormat;
  private unsubscribe: any = () => {};
  private checkPlan: boolean = false;
  private companyText: any = "";
  created() {
    this.init();
  }

  mounted() {
    window.addEventListener("scroll", () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.showButtonScrollToBottom = false;
      }
    });

    (this.$refs.lstMessageGroup as any).addEventListener("scroll", () => {
      let lstMsgScroll = this.$refs.lstMessageGroup as any;
      const reachBottom =
        lstMsgScroll.scrollTop + lstMsgScroll.clientHeight ===
        lstMsgScroll.scrollHeight;
      if (reachBottom) {
        this.showButtonScrollToBottom = false;
      }
    });
  }

  beforeDestroy() {
    window.removeEventListener("scroll", () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.showButtonScrollToBottom = false;
      }
    });

    (this.$refs.lstMessageGroup as any).removeEventListener("scroll", () => {
      let lstMsgScroll = this.$refs.lstMessageGroup as any;
      const reachBottom =
        lstMsgScroll.scrollTop + lstMsgScroll.clientHeight ===
        lstMsgScroll.scrollHeight;
      if (reachBottom) {
        this.showButtonScrollToBottom = false;
      }
    });
  }

  destroyed() {
    this.unsubscribe();
  }

  //   updated() {
  //     if (!this.checkUserSendMessage) {
  //       this.checkUserSendMessage = !this.checkUserSendMessage
  //     }
  //   }

  @Watch("$route.params.groupChatId")
  async init() {
    this.reset();
    this.checkBannerShowOff();
    this.checkMobile();
    this.setUserLogin(this.userLogin);
    if (!this.currentUser.userId) return;
    this.idLogin = this.currentUser.userId;
    if (this.$route.params && this.$route.params.groupChatId) {
      this.groupItemSelected = Number(this.$route.params.groupChatId);
    }

    //init text editor
    this.initTextEditor();

    //call API get message
    this.getAllLstMessageInGroupChat();

    //init firebase to handle realtime
    this.handleRealtime();

    //get group chat infomation
    await this.getInfoGroupChat();

    //mark read group
    this.markReadGroupChat();
  }

  reset() {
    this.unsubscribe();
    this.idGroupChat = Number(this.$route.params.groupChatId);
    this.lstMessageGroupChatDefault = [];
    this.lstMessageGroupChat = [];
    this.page = 1;
    this.lastPage = 1;
    this.searchPage = 1;
    this.pageUp = 1;
    this.pageDown = 1;
    this.isPageUp = false;
    this.isPageDown = false;
    this.loadMoreDown = false;
    this.disablePageUp = false;
    this.disablePageDown = false;
  }

  @Watch("editorData")
  watchEditorData() {
    const text = this.removeHTML(this.editorData);
    this.isOverLength =
      new Blob([text]).size > 80000 || _.toArray(text).length > 20000;
    this.isIncludesScriptTag = checkScript(this.removeHTML(this.editorData));
  }

  /**
   * beforeRouteLeave
   */
  beforeRouteLeave(to: any, from: any, next: any) {
    this.markReadGroupChat();

    let msgInput: string = this.editorData
      ? this.editorData
      : // : (this.$refs.inputMessageChat as any).innerText
        this.contentTextArea;

    //this case for modal search header
    if (document.querySelectorAll("#modal-search-header").length) {
      next();

      //normal case
    } else if (msgInput.trim().length) {
      this.confirmChange = next;
      this.$bvModal.show("modal-confirm-unsave-leave");
    } else {
      next();
    }
  }

  confirm() {
    this.confirmChange();
  }

  checkBannerShowOff() {
    this.bannerShowOff =
      this.$store.state.setting.setting.setting_themes.theme
        .banner_header_bg_color ||
      this.$store.state.setting.setting.setting_themes.theme
        .banner_header_bg_img;
  }

  checkMobile() {
    this.isMobile = CheckDevice.isMobile();
    this.isSafariIos = CheckDevice.iOSBrowerName() === CheckDevice.safari;
  }

  async findMessageByNo(messageReply: any) {
    this.resetPage();
    await this.$router
      .replace({ query: { message: messageReply.messageId } })
      .catch(() => {});
    this.lstMessageGroupChatDefault = [];
    this.lstMessageGroupChat = [];
    this.getAllLstMessageInGroupChat();
  }

  resetPage() {
    this.page = 1;
    this.lastPage = 1;
    this.searchPage = 1;
    this.pageUp = 1;
    this.pageDown = 1;
    this.isPageUp = false;
    this.isPageDown = false;
    this.loadMoreDown = false;
    this.disablePageUp = false;
    this.disablePageDown = false;
  }

  @Watch("lstFileUpload")
  showFileUpload() {
    this.isVisiableLstUploadFile = !!this.lstFileUpload.length;
  }

  @Watch("groupChatName")
  groupChatNameOnChange() {
    this.groupChatOver200 = !(
      new Blob([this.groupChatName]).size <= 255 * 4 &&
      _.toArray(this.groupChatName).length <= 255
    );
  }

  chatBoxNameFocus() {
    const chatBox: any = this.$refs.chatBoxName;
    chatBox.focus();
    chatBox.classList.value = "form-control p-0";
    this.chatBoxNameColor = "#000";
  }

  chatBoxNameBlur() {
    const chatBox: any = this.$refs.chatBoxName;
    chatBox.classList.value = "form-control border-0 bg-none p-0";
    this.chatBoxNameColor =
      this.$store.state.setting.setting.setting_themes.theme.title_color;

    //validate
    if (!this.groupChatName || this.groupChatOver200) {
      this.groupChatName = this.groupChatSelected.gCName;
      return;
    }

    this.$blockui.show();
    GroupChatService.updateGroupChatName(
      this.$route.params.groupChatId,
      this.groupChatName
    ).finally(() => {
      this.$blockui.hide(false);
      chatBox.blur();
    });
  }

  /**
   * init text editor
   */
  initTextEditor() {
    // set key editor
    this.getKey = process.env.VUE_APP_TINY_MCE_API_KEY as string;
    this.getTinyConfig = {
      language: this.$store.state.setting.setting.setting_language.code,
      height: 500,
      setup: (editor: any) => {
        editor.ui.registry.addContextToolbar("textselection", {
          predicate: (node: any) => {
            return !editor.selection.isCollapsed();
          },
          items: "bold italic | quicklink h1 h2 h3 blockquote",
          position: "selection",
          scope: "node",
        });
      },
      selector: "textarea#full-featured",
      deprecation_warnings: false,
      nonbreaking_force_tab: true,
      toolbar_mode: "wrap",
      menubar: true,
      entity_encoding: "raw",
      plugins: [
        "advlist emoticons hr image link lists media nonbreaking preview searchreplace table",
      ],
      toolbar: [
        "fontsizeselect | fontselect | formatselect | undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | emoticons preview | image media link | searchreplace hr nonbreaking | table",
      ],
      contextmenu: false,
      quickbars_insert_toolbar: false,
      image_dimensions: false,
      file_picker_callback: (cb: any, value: any, meta: any) => {
        var input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");

        document.body.appendChild(input);

        input.onchange = (event: any) => {
          const files: File[] = event.target.files || event.dataTransfer.files;
          if (!files.length) return;
          if (
            files[0].size < this.maxFileSize.bytes &&
            this.imageType.includes(files[0].type)
          ) {
            let formData = new FormData();
            formData.set("file", files[0]);
            formData.set("group_chat_id", String(this.idGroupChat));
            this.$blockui.show();
            GroupChatService.uploadFileByTiny(formData)
              .then((res) => {
                if (res.status === 200) {
                  cb(res.data.image_path, { title: files[0].name });
                  // if (res.data.file_id) {
                  //   this.fileIds.push(res.data.file_id)
                  // }
                }
              })
              .catch((err) => {
                //@ts-ignore
                tinymce.activeEditor.undoManager.undo();
              })
              .finally(() => this.$blockui.hide(false));
          } else {
            this.$bvModal.show("modal-error-text-editor");
          }
          document.body.removeChild(input);
        };
        input.click();
      },
      images_upload_handler: (blobInfo: any, success: any, failure: any) => {
        if (
          blobInfo.blob().size < this.maxFileSize.bytes &&
          this.imageType.includes(blobInfo.blob().type)
        ) {
          let formData = new FormData();
          formData.set("file", blobInfo.blob());
          formData.set("group_chat_id", String(this.idGroupChat));
          this.$blockui.show();
          GroupChatService.uploadFileByTiny(formData)
            .then((res) => {
              if (res.status === 200) {
                success(res.data.image_path);
                // if (res.data.file_id) {
                //   this.fileIds.push(res.data.file_id)
                // }
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => this.$blockui.hide(false));
        } else {
          failure(
            this.$t("common.form.img_modified_with_max", {
              max: this.maxFileSize.mb,
            })
          );
          //@ts-ignore
          tinymce.activeEditor.undoManager.undo();
        }
      },
    };
  }

  get userLogin() {
    return this.$store.state.userInfo.user;
  }

  handleFileUpload(event: any) {
    const files: File[] = event.target.files || event.dataTransfer.files;
    if (!files.length) return;
    this.fileUpload = files[0];
    this.uploadFileMsg();
  }

  async uploadFileMsg() {
    let file_path = "";
    let file_id = "";
    let file_name = this.fileUpload.name;
    let formData = new FormData();
    formData.set("file", this.fileUpload);
    formData.set("group_chat_id", String(this.idGroupChat));
    let errorTiny: boolean = false;
    this.$blockui.show();
    await GroupChatService.uploadFileByTiny(formData)
      .then((res) => {
        if (res.status === 200) {
          file_path = res.data.image_path;
          file_id = res.data.file_id;
        }
      })
      .catch((err) => {
        errorTiny = true;
        if (err.response.status === 422) {
          if (err.response.data.message === "MAX_TOTAL_FILE_SIZE") {
            this.modalAction = () => {};
            this.modalMess = this.$t("common.message.upload_over_size", {
              max: 25,
            }) as string;
          }
        }
        if(err.response.status === 413) {
            this.modalAction = () => {};
            this.modalMess = this.$t("common.message.upload_over_size", {
              max: 25,
            }) as string;
        }

        this.$bvModal.show("modal-error-chat-detail");
      })
      .finally(() => this.$blockui.hide(false));
    let file_path_html = this.imageType.includes(this.fileUpload["type"])
      ? `<img class="mh--45 mw--45 mh-xl--100 mw-xl--100" src="${file_path}"/>`
      : `<div class="text-primary fwb">${file_name}</div>`;
    formData.set("content", file_path_html);
    formData.set("file_id", file_id);

    if (this.fileUpload && !errorTiny) {
      GroupChatService.uploadFile(this.idGroupChat, formData)
        .then((res) => {
          if (res.status === 200) {
            this.$nextTick(() => {
              let scrollHeight = (this.$refs.lstMessageGroup as any)
                ?.scrollHeight;
              let lstMsgScroll = this.$refs.lstMessageGroup as any;
              lstMsgScroll.scrollTop = scrollHeight;
            });
            this.isVisableReplyMessage = false;
            // ;(this.$refs.inputMessageChat as any).innerHTML = ''
            this.contentTextArea = "";
            this.lstFileUpload = [];
          }
          this.fileUpload = null;

          this.checkUserSendMessage = true;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            if (err.response.data.message === "USER_MAX_UPLOAD_SIZE_IN_CHAT") {
              this.modalMess = (this.$store.state.userInfo.user.info.name +
                this.$t("common.message.error_user_max_chat_size")) as string;
              this.modalAction = () => {};
            }
            this.$bvModal.show("modal-error-chat-detail");
          }
        });
    }
  }

  async sendMsgByTiny() {
    if (this.editorData && !this.isOverLength && !this.isIncludesScriptTag) {
      this.messageSpecial = true;
      await this.sendMessage();
      this.editorData = "";
    } else {
      this.messageSpecial = false;
    }
  }

  removeHTML(content: string) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = content;
    return tmp.textContent || tmp.innerText || "";
  }

  inputFocus() {
    if (CheckDevice.isMobile()) {
      this.showFooter = false;
    } else {
      this.showFooter = true;
    }
  }

  inputBlur() {
    setTimeout(() => {
      this.showFooter = true;
    }, 1);
  }

  private sendMessage() {
    if (this.overLengthContent || this.includesScriptTagContent) return;
    let vm = this;
    let msgInput = this.editorData
      ? this.editorData.replace(
          "<img",
          '<img class="mh--45 mw--45 mh-xl--100 mw-xl--100"'
        )
      : // : (this.$refs.inputMessageChat as any).innerHTML
        this.contentTextArea;

    //remove text on UI
    // ;(this.$refs.inputMessageChat as any).innerHTML = ''
    this.contentTextArea = "";

    // remove br tag
    msgInput = msgInput.replace(/^(<br>)+|(<br>)+$/g, "");
    if (
      !this.editorData &&
      !this.removeHTML(msgInput).trim() &&
      this.lstFileUpload.length === 0
    ) {
      // ;(this.$refs.inputMessageChat as any).innerHTML = ''
      this.contentTextArea = "";
      return;
    }
    let messageNew: MessageModel = new MessageModel();
    messageNew.content = msgInput;
    if (this.currentUser.userId) {
      messageNew.senderId = this.currentUser.userId;
    }

    if (this.groupItemSelected) {
      messageNew.groupChatId = this.groupItemSelected;
    }
    messageNew.userName = this.currentUser.userName;
    messageNew.userAvatar = this.currentUser.avatar;
    messageNew.timeSent = new Date();
    // set message reply
    let msgReply = null;
    if (this.isVisableReplyMessage && this.messageReply) {
      msgReply = this.messageReply.messageId;
    }
    messageNew.messageSpecial = this.messageSpecial;
    if (this.lstFileUpload.length > 0) {
      this.lstFileUpload.forEach((fileUpload: any) => {
        // file upload html
        let fileUploadHtml =
          '<div class="d-flex justify-content-between rouned align-items-center hover-file-upload bg-light w--80 mh--20 me-5 mt-2">' +
          '<div class="d-flex flex-row align-items-center">' +
          '<span class="far fa-file px-2 fs-34"></span>' +
          '<div class="d-flex flex-column">' +
          "<span>" +
          fileUpload.name +
          "</span>" +
          "<span>" +
          fileUpload.size +
          "</span>" +
          "</div></div>" +
          '<a href="' +
          fileUpload.url +
          '" download><span class="fal fa-file-download cursor-pointer btn-upload text-secondary-tint-less pe-2 opacity-0" /></a>' +
          "</div>";
        messageNew.content = messageNew.content + fileUploadHtml;
      });
    }
    // set data
    let messageNewDb = {
      content: messageNew.content,
      content_text: this.removeHTML(messageNew.content),
      sender_id: messageNew.senderId,
      group_chat_id: messageNew.groupChatId,
      user_name: messageNew.userName,
      user_avatar: messageNew.userAvatar,
      time_sent: messageNew.timeSent,
      is_deleted: "",
      is_like: "",
      message_special: this.messageSpecial,
      reply_message: msgReply,
      file_ids: this.fileIds,
    };
    // send message
    // send api to BE and BE return message new
    MessageService.sendMessage(messageNewDb)
      .then((res: any) => {
        if (res.status === 200) {
          //   if (res.data) {
          this.checkUserSendMessage = true;
          // this.scrollToBottom()
          //   }
        }
      })
      .catch((err: any) => {
        if (
          err.response.status === 404 &&
          err.response.data.error === "SEND_MESSAGE_DENIED"
        ) {
          this.goToListGroupChat();
        }
      })
      .finally(() => {
        this.messageSpecial = false;
        // if (this.checkUserSendMessage) {
        //   this.scrollToBottom()
        //   this.checkUserSendMessage = false
        // }
      });
  }

  private async getInfoGroupChat() {
    if (this.groupItemSelected) {
      await GroupChatService.getGroupChatById(this.groupItemSelected)
        .then((res: any) => {
          if (res.status === 200) {
            if (res && res.data) {
              this.setInfoGroupChat(res.data);
            }
          }
        })
        .catch((err: any) => {
          if (err.response.status === 404) {
            this.modalMess = this.$t(
              "common.message.can_not_access_group_chat"
            ) as string;
            this.modalAction = () => this.goToListGroupChat();
            this.$bvModal.show("modal-error-chat-detail");
          }
        });
    }
  }

  private async setInfoGroupChat(data: any) {
    let timeSent = "";
    if (data.updated_at) {
      if (
        moment(data.updated_at).format("DD/MM/YYYY") ===
        moment().format("DD/MM/YYYY")
      ) {
        timeSent = moment(data.updated_at).format("HH:mm");
      } else {
        timeSent = `${moment(data.updated_at).format("DD/MM")}（${moment(
          data.updated_at
        ).format("dddd")}）`;
      }
    }
    // favorite group chat
    let favoriteGroupChat: boolean = false;
    favoriteGroupChat = data.is_favorite;

    // set group chat name
    const name = this.handleSetGroupChatName(data);
    this.groupChatSelected = new GroupChatModel(
      data,
      name,
      favoriteGroupChat,
      timeSent
    );

    this.groupSelected = data.group;

    //group chat name
    this.groupChatName = name;

    //handle unread message
    this.unreadMeesageStartId = data.last_message_id;
    this.getUnreadMeesageEndId();
  }

  private setUserLogin(user: any) {
    this.$set(this.currentUser, "userId", user.info.id);
    this.$set(this.currentUser, "userName", user.info.name);
    this.$set(this.currentUser, "avatar", user.profile.avatar);
  }

  @Watch("contentTextArea")
  inputMessage() {
    let scrollHeight = 29;
    setTimeout(() => {
      scrollHeight = (this.$refs.inputTextArea as any).scrollHeight - 21;
      const row = Math.ceil(scrollHeight / 21);
      this.textAreaRows = row ? row : 1;
    }, 2);
    setTimeout(() => {
      // const row = this.contentTextArea.split('\n').length
      const row = Math.round(scrollHeight / 21);
      this.textAreaRows = row ? row : 1;
    }, 1);

    // const text = (this.$refs.inputMessageChat as any).innerText
    const text = this.contentTextArea;

    this.overLengthContent =
      new Blob([text]).size > 80000 || _.toArray(text).length > 20000;
    this.includesScriptTagContent = checkScript(text);

    // if (
    //   (this.$refs.inputMessageChat as any) &&
    //   (this.$refs.inputMessageChat as any).innerHTML === ''
    // ) {
    //   this.messageSpecial = false
    // }
  }

  private resetEditor() {
    this.editorData = "";
    this.messageSpecial = false;
  }

  private viewProfileUser(message: MessageModel) {
    if (message.userStatus === EUserStatus.NORMAL) {
      this.$bvModal.show("modal-user-in-chat-detail");
      this.userId = message.senderId;
    }
  }

  private async likeMessage(msgId: any) {
    this.$blockui.show();
    let vm = this;
    if (!msgId) return;
    let msgChoose = this.lstMessageGroupChatDefault.find(
      (msg: any) => msg.messageId === msgId
    );
    const userLike = msgChoose && msgChoose.isLike ? 0 : 1;
    let dataLike = {
      react_type: 1,
      like: userLike,
    };
    // api
    await MessageService.reactMessage(msgId, dataLike)
      .then((res: any) => {
        if (res.status === 200) {
          // update message
          // send api edit message return
          // if update lst delete
          let unsubscribe = messagesRef
            .where("messageId", "==", msgId)
            .onSnapshot((querySnapshot: any) => {
              querySnapshot.forEach((snapshot: any) => {
                let msgUpdate: any = {};
                msgUpdate.id = snapshot.id;
                // update in firebase
                if (msgUpdate.id) {
                  let data = {
                    id: msgUpdate.id,
                    type: 1,
                  };

                  MessageService.updateDataLike(msgId, data);
                }

                unsubscribe();
              });
            });
        }
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => this.$blockui.hide(false));
  }

  replyMessage(msgId: any) {
    if (!msgId) {
      return;
    }

    // set reply message
    this.messageReply = new MessageModel();
    this.isVisableReplyMessage = true;
    let msgReply = this.lstMessageGroupChatDefault.find((msg: any) => {
      return msg.messageId === msgId;
    });
    if (msgReply) {
      this.messageReply = msgReply;
      // ;(this.$refs.inputMessageChat as any).focus()
      (this.$refs.inputTextArea as any).focus();
    } else {
      return;
    }
  }

  async findMessageInList(messageReply: any) {
    if (!messageReply.isDeletedByAuthor) {
      let el = document.getElementById(`message-${messageReply.messageId}`);
      await this.clickLoadMoreMessage();
      if (messageReply.currentPage === 1) {
        el || messageReply.messFound
          ? this.scrollToEl(messageReply)
          : this.scrollToBottom();
        return;
      }
      if (!el) {
        await this.clickLoadMoreMessageDown();
        if (!el) {
          this.scrollToEl(messageReply);
        } else {
          this.scrollToEl(messageReply);
        }
      } else {
        this.scrollToEl(messageReply);
      }
    }
  }

  scrollToEl(messageReply: any) {
    let el = document.getElementById(`message-${messageReply.messageId}`);
    setTimeout(() => {
      if (document.body.clientWidth > 1200) {
        el?.scrollIntoView({
          block: "center",
          inline: "center",
        });
      } else {
        el?.scrollIntoView({
          block: "center",
          inline: "center",
        });
      }
      this.blinkMessageId = Number(messageReply.messageId);
    }, 10);
    setTimeout(() => {
      this.blinkMessageId = -1;
    }, 1000);
  }

  copyMessageLink(messageId: number) {
    const link = `${window.location.origin}${this.$route.path}?message=${messageId}`;
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard
        .writeText(link)
        .then(() => this.$bvModal.show("copy-info-modal"))
        .catch((error) => console.log(error));
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = link;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      if (document.execCommand("copy")) {
        this.$bvModal.show("copy-info-modal");
      }
      textArea.remove();
    }
  }

  /**
   * Open delete message confirm
   */
  openModalConfirmDelMess(mess: any) {
    this.deleteMess = mess;
    if (mess.isCanDelete && !mess.isOwnMsg) {
      this.deleteMessage(true);
    } else {
      this.$bvModal.show(`confirm-modal-delete-message`);
    }
  }

  /**
   * Open delete modal delete message success
   */
  messageDeleted(no: string) {
    this.infoMess = `No.${no} ${this.$t("common.message.deleted")}`;
    this.$bvModal.show("modal-success-chat-detail");
  }

  /**
   * Delete message by id
   */
  async deleteMessage(noShowSuccessModal?: boolean) {
    let vm = this;
    const msgId = this.deleteMess.messageId;
    if (!msgId) {
      return;
    }
    let dataDelete = {
      react_type: 2,
    };
    // api
    await MessageService.reactMessage(msgId, dataDelete)
      .then((res: any) => {
        if (res.status === 200) {
          this.listDeletedMessage.push(msgId);
          // update message
          // send api edit message return
          // if update lst delete
          let unsubscribe = messagesRef
            .where("messageId", "==", msgId)
            .onSnapshot((querySnapshot: any) => {
              querySnapshot.forEach((snapshot: any) => {
                let msgUpdate: any = {};
                msgUpdate.id = snapshot.id;
                // update in firebase
                if (msgUpdate.id) {
                  let data = {
                    id: msgUpdate.id,
                    type: 2,
                  };
                  MessageService.updateDataDelete(msgId, data);
                }

                unsubscribe();
              });
            });
        }
        if (!noShowSuccessModal) {
          this.messageDeleted(this.deleteMess.index);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private removeUploadFile(url: any) {
    if (!url) {
      return;
    }
    this.lstFileUpload = this.lstFileUpload.filter((file: any) => {
      return file.url !== url;
    });
  }

  async getUnreadMeesageEndId() {
    let dataSearch = {
      groupChatId: this.groupItemSelected,
      limit: 1,
      page: 1,
    };
    return MessageService.getAllMessageInGroupChat(dataSearch).then(
      (res: any) => {
        if (res.status === 200) {
          this.unreadMeesageEndId = res.data?.data.data[0]?.id;
        } else {
             this.$router.push({ name: 'home' })
        }
      }
    );
  }

  async getAllLstMessageInGroupChat() {
    let dataSearch = {
      groupChatId: this.groupItemSelected,
      limit: this.limitMessage,
      page: 1,
      messageSearchId: this.$route.query.message
        ? this.$route.query.message
        : "",
    };

    // this.screenLoading = !!!this.$route.query.message //if route have query message, set screenLoading to false

    return MessageService.getAllMessageInGroupChat(dataSearch)
      .then(async (res: any) => {
        if (res.status === 200) {
          let reachBottom = false;

          if (document.body.clientWidth > 1200) {
            //check if user is scroll bottom
            let lstMsgScroll = this.$refs.lstMessageGroup as any;
            reachBottom =
              lstMsgScroll.scrollTop + lstMsgScroll.clientHeight ===
              lstMsgScroll.scrollHeight;
          } else {
            reachBottom =
              window.innerHeight + window.scrollY >= document.body.offsetHeight;
          }

          //binding data
          const haveNewMessage = await this.setLstMessageInGroupChat(
            res.data.data
          );

          // if this call is because this user send message, scroll to bottom
          // if this call is call when scroll is at bottom, scroll to bottom to get newer message

          setTimeout(() => {
            if (reachBottom || this.checkUserSendMessage) {
              this.scrollToBottom();
              this.checkUserSendMessage = false;
            } else {
              if (!this.showButtonScrollToBottom) {
                this.showButtonScrollToBottom = haveNewMessage;
              }
            }

            // if this is first time loading this page, scroll to bottom
            if (this.firstLoad) {
              this.scrollToBottom();
              this.showButtonScrollToBottom = false;
              this.firstLoad = false;
            }

            this.screenLoading = false;
          }, 300);

          if (this.$route.query.message) {
            setTimeout(() => {
              this.findMessageInList({
                messageId: this.$route.query.message,
                currentPage: res.data.data.current_page,
                messFound: res.data.page_by_message_exist,
              });
              this.$router.replace({ query: {} }).catch(() => {});
            }, 500);
          }

          if (!res.data.check_plan_company) {
            this.checkPlan = !res.data.check_plan_company;
          }
          this.companyText = res.data.text_msg;

          if (!res.data.page_by_message_exist) {
            this.modalMess = this.$t(
              "common.message.message_not_found"
            ) as string;
            this.$bvModal.show("find-message-not-found");
          }

          this.loadingLstMessage = false;
        }
        else {
            this.$router.push({ name: 'home' })
        }
      })
      .catch((err: any) => {
        this.$router.push({ name: 'home' })
        console.log(err);
      });
  }

  scrollToBottom() {
    if (document.body.clientWidth > 1200) {
      let lstMsgScroll = this.$refs.lstMessageGroup as any;
      const h = lstMsgScroll?.scrollHeight + 100;
      //   lstMsgScroll.scrollTo(0, h)
      lstMsgScroll.scrollTo({
        top: h,
        left: 0,
        behavior: "smooth",
      });
    } else {
      (this.$refs.inputTextArea as any)?.blur();

      setTimeout(() => {
        const h = document.body.scrollHeight + 100;
        window.scrollTo({
          top: h,
          left: 0,
          behavior: "smooth",
        });
      }, 150);
    }
    this.isVisableReplyMessage = false;
    this.showButtonScrollToBottom = false;
    this.lstFileUpload = [];
  }

  handleRealtime() {
    let vm = this;
    let trackingRealtime = true;
    const start = this.moment().subtract(1, "m").toDate();
    this.unsubscribe = messagesRef
      .where("groupChatId", "==", this.groupItemSelected)
      .where("createdAt", ">", start)
      .onSnapshot((querySnapshot: any) => {
        if (trackingRealtime) {
          trackingRealtime = false;
        } else {
          // check message updated
          querySnapshot.docChanges().forEach((messageChange: any) => {
            if (
              messageChange.type === "modified" ||
              messageChange.type === "added"
            ) {
              if (messageChange.doc.data().messageId) {
                let msgIdUpdate = messageChange.doc.data().messageId;
                MessageService.getDetailMessage(msgIdUpdate)
                  .then((res: any) => {
                    if (res.status === 200) {
                      // check user login like msg
                      let checkUserLike: boolean = false;
                      if (res.data.liker.length > 0) {
                        res.data.liker.forEach((userLike: any) => {
                          if (userLike.id === vm.currentUser.userId) {
                            checkUserLike = true;
                          }
                        });
                      }
                      // check msg in lstMessageGroupChatDefault
                      let msgChange = vm.lstMessageGroupChatDefault.find(
                        (msg: any) => {
                          return msg.messageId === res.data.id;
                        }
                      );

                      // check msg in lstMessageGroupChat
                      if (msgChange) {
                        msgChange.content = res.data.content;
                        msgChange.liker = res.data.liker;
                        msgChange.isLike = checkUserLike;
                        msgChange.isCanDelete = res.data.is_can_delete;
                        // msgChange.isOwnMsg = res.data.reply_message.is_own_msg
                      }
                      let msgChange1 = vm.lstMessageGroupChat.find(
                        (msg: any) => {
                          return msg.messageId === res.data.id;
                        }
                      );
                      if (msgChange1) {
                        msgChange1.content = res.data.content;
                        msgChange.liker = res.data.liker;
                        msgChange1.isLike = checkUserLike;
                        msgChange1.isCanDelete = res.data.is_can_delete;
                      }
                    }
                  })
                  .catch((err: any) => {
                    console.log(err);
                  });

                // check msg delete
                let delete_status: number = 0;
                delete_status = messageChange.doc.data().delete;
                if (delete_status > 0) {
                  vm.lstMessageGroupChatDefault =
                    vm.lstMessageGroupChatDefault.filter((msg: any) => {
                      return msg.messageId !== msgIdUpdate;
                    });
                  vm.lstMessageGroupChat = vm.lstMessageGroupChat.filter(
                    (msg: any) => {
                      return msg.messageId !== msgIdUpdate;
                    }
                  );
                }
              }
            }
          });
          this.getAllLstMessageInGroupChat();
          this.markReadGroupChat();
        }
      });
  }

  private async fetchMessagesByGroupChatId() {
    if (this.groupChatSelected.groupChatId) {
      let vm = this;
      // this.loadingLstMessage = true
      let dataSearch = {
        groupChatId: vm.groupChatSelected.groupChatId,
        limit: vm.limitMessage,
        page: vm.page,
      };
      return MessageService.getAllMessageInGroupChat(dataSearch)
        .then((res: any) => {
          if (res.status === 200) {
            if (res && res.data) {
              this.setLstMessageInGroupChat(res.data.data);
            }
          } else {
            this.$router.push({ name: 'home' })
          }
        })
        .catch((err: any) => {
          this.$router.push({ name: 'home' })
          console.log(err);
        })
        .finally(() => {
          // this.loadingLstMessage = false
          if (this.checkFirstScrollBottom) {
            this.checkFirstScrollBottom = false;
          }
        });
    }
  }

  private setLstMessageInGroupChat(data: any): Promise<boolean> {
    return new Promise((resolve, __) => {
      if (data.data.length > 0) {
        let allMessagesRead = data.data.map((msg: any) => {
          let time = "";
          let day = "";
          let dayWeek = "";
          let dayReply = "";
          let timeReply = "";
          let dayWeekReply = "";
          let messageReply: MessageReply | null = null;
          if (msg.created_at) {
            day = moment(msg.created_at).format("MM/DD");
            time = moment(msg.created_at).format("HH:mm");
            dayWeek = moment(msg.created_at).format("dd");
          }
          if (msg.reply_msg) {
            dayReply = moment(msg.reply_msg.created_at).format("DD/MM");
            timeReply = moment(msg.reply_msg.created_at).format("HH:mm");
            dayWeekReply = moment(msg.reply_msg.created_at).format("dddd");

            messageReply = new MessageReply(
              msg,
              dayReply,
              dayWeekReply,
              timeReply
            );
          }

          // check user login like msg
          let checkUserLike: boolean = false;
          if (msg.liker.length > 0) {
            msg.liker.forEach((userLike: any) => {
              if (userLike.id === this.currentUser.userId) {
                checkUserLike = true;
              }
            });
          }
          return {
            messageId: msg.id,
            content: msg.content,
            senderId: msg.sender_id,
            groupChatId: msg.group_chat_id,
            userName: msg.sender.name,
            userAvatar:
              msg.sender.user_active_status === EUserStatus.LEAVE_SYSTEM
                ? ""
                : msg.sender.user_profile
                ? msg.sender.user_profile.avatar
                : "",
            userStatus: msg.sender.user_active_status,
            timeSent: msg.time_sent,
            day: day,
            dayWeek: `（${dayWeek}）`,
            time: time,
            liker: msg.liker,
            isLike: checkUserLike,
            isCanDelete: msg.is_can_delete,
            replyMessage: messageReply,
            index: msg.no_in_group_chat,
            messageSpecial: msg.message_special,
            isOwnMsg: msg.is_own_msg,
            file: msg.file,
            is_html_content: msg.is_html_content,
            deleted_at: msg.deleted_at,
          };
        });

        this.lastPage = data.last_page;

        if (this.$route.query.message) {
          this.page = data.current_page;
          this.pageUp = data.current_page;
          this.pageDown = data.current_page;
          this.searchPage = data.current_page;
          this.disablePageUp = this.page === this.lastPage;
          this.disablePageDown = this.page === 1;
          this.loadMoreDown = data.current_page !== 1;
        }

        //filter dulicate
        let ids = new Set(
          this.lstMessageGroupChatDefault.map((d) => d.messageId)
        );
        const newMessages = allMessagesRead.filter(
          (d: any) => !ids.has(d.messageId)
        );
        let merged = [...this.lstMessageGroupChatDefault, ...newMessages];

        //sort by message id
        this.lstMessageGroupChatDefault = _.sortBy(merged, "messageId");

        this.lstMessageGroupChat = this.lstMessageGroupChatDefault;

        resolve(!!newMessages.length);
      } else if (data.data.length === 0) {
        this.lstMessageGroupChat = [];
      }

      if (this.lstMessageGroupChat.length === 0) {
        this.showNoMessage = true;
      } else {
        this.showNoMessage = false;
      }

      resolve(false);
    });
  }

  hanldeUserStatus(name: string, status: EUserStatus) {
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return (
        name +
        this.$t("common.suffix.san") +
        this.$t("common.suffix.leave_group")
      );
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t("common.suffix.leave_system") as string;
    return name + this.$t("common.suffix.san");
  }

  handleGroupNameLeave(name: string, status: EUserStatus) {
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + this.$t("common.suffix.leave_group");
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t("common.suffix.leave_system") as string;
    return name;
  }

  private openModalGallery() {
    this.openGallery = true;
  }

  private closeModalGallery() {
    this.openGallery = false;
  }

  private inviteUser() {
    this.showModalManageMember = true;
  }

  goToListGroupChat() {
    if (this.$route.params.from) {
      this.$router.push(this.$route.params.from);
    } else {
      this.$router.push({ name: "chat-list" });
    }
  }

  goToListGroupChatRoute() {
    if (this.$route.params.from) {
      return this.$route.params.from;
    } else {
      return { name: "chat-list" };
    }
  }

  private async outGroupChat() {
    this.$bvModal.show("confirm-leave-chat-list");
  }

  private async favoriteGroupChat() {
    this.$blockui.show();
    let groupChatId = this.groupChatSelected.groupChatId;
    await GroupChatService.toggleFavouriteGroupChat(groupChatId)
      .then((res: any) => {
        if (res.status === 200) {
          this.groupChatSelected.favorite = !this.groupChatSelected.favorite;
          triggerFavorite();
        }
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => this.$blockui.hide(false));
  }

  async clickLoadMoreMessage() {
    if (this.disablePageUp) return;

    if (this.screenLoading) {
      this.screenLoading = false;
      return;
    }
    // if (this.firstLoad) {
    //   this.firstLoad = false
    //   return
    // }

    //if last loadmore is page down -> set current page to last page up
    if (this.isPageDown) this.page = this.pageUp;

    //set loadmore to page up
    this.isPageUp = true;

    //re-count page
    ++this.page;

    //if current page is search page (search page is have data loaded at screen start), continues re-count
    if (this.page === this.searchPage) ++this.page;

    //set last page up = this current page
    this.pageUp = this.page;

    //if page is max (= lastPage) disable page up forever
    this.disablePageUp = this.page === this.lastPage;

    //call API to get data and scroll to last - old message
    let lstMsgScroll = this.$refs.lstMessageGroup as any;
    const oldBodyHiehgt = document.body.scrollHeight;
    const oldListMessHiehgt = lstMsgScroll.scrollHeight;
    await this.fetchMessagesByGroupChatId();
    // if (this.firstLoad) {
    //   this.firstLoad = false
    //   return
    // }
    if (document.body.clientWidth > 1200) {
      const h = lstMsgScroll.scrollHeight - oldListMessHiehgt;
      lstMsgScroll.scrollTo(0, h);
    } else {
      const h = document.body.scrollHeight - oldBodyHiehgt;
      window.scrollTo(0, h);
    }
  }

  async clickLoadMoreMessageDown() {
    if (this.disablePageDown) return;

    //if last loadmore is page up -> set current page to last page down
    if (this.isPageUp) this.page = this.pageDown;

    //set loadmore to page down
    this.isPageDown = true;

    //re-count page
    --this.page;

    //if current page is search page (search page is have data loaded at screen start), continues re-count
    if (this.page === this.searchPage) --this.page;

    //set last page down = this current page
    this.pageDown = this.page;

    //if page is min (= 1) disable page up forever
    this.disablePageDown = this.page === 1;
    //call API to get data
    await this.fetchMessagesByGroupChatId();
  }

  downloadGalleyExist(msg: any) {
    let file_id = msg.file[0].id;
    this.file_name_download = msg.file[0].file_name;
    let fileIds = {
      file_ids: [file_id],
    };

    //handle dowload IOS not safari
    if (
      CheckDevice.iOSBrowerName().toLowerCase() !== CheckDevice.safari &&
      CheckDevice.isMobile() &&
      CheckDevice.getMobileOS() === CheckDevice.ios
    ) {
      window.open(msg.file[0].path);
      return;
    }

    this.$blockui.show();
    GroupChatService.downloadGallery(fileIds)
      .then((res) => {
        if (res.status === 200) {
          this.hanldeDownloadFile(res);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => this.$blockui.hide(false));
  }

  hanldeDownloadFile(res: any) {
    const bytes = new Uint8Array(res.data);
    const blob = new Blob([bytes], { type: "*" });
    let a = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.setAttribute("download", this.file_name_download);
    a.click();
  }

  handleSetGroupChatName(group: any) {
    if (!group.only_user && !group.onlyUser) {
      return group.name ? group.name : group.gCName;
    }

    if (!group.only_user && !group.onlyUser && !group.user_group_chat.length) {
      return group.name ? group.name : group.gCName;
    }

    let groupChatName = group.user_group_chat;

    if ((group.only_user || group.onlyUser) && group.user_leave_chat) {
      groupChatName = [
        {
          name: this.handleGroupNameLeave(
            group.user_leave_chat.name,
            group.user_leave_chat.leaveType
          ),
        },
      ];
    }

    if (!groupChatName) return;
    let groupName =
      (groupChatName.length > 2 ? groupChatName.slice(0, 2) : groupChatName)
        .map((item: any) => item.name)
        .join(`${this.$t("common.suffix.san")}${this.$t("chat.comma")}`) +
      this.$t("common.suffix.san");
    let otherUserCount =
      groupChatName.length > 2 ? groupChatName.length - 2 : 0;
    let groupNameFull =
      String(groupName) +
      (otherUserCount > 0
        ? this.$t("chat.chat_group_name_with", {
            other_user_count: otherUserCount,
          })
        : "") +
      this.$t("chat.chat_with");
    return groupNameFull;
  }

  /**
   * Mark read group when focus input chat
   */
  markReadGroupChat() {
    let data: any = {
      group_chat_id: this.idGroupChat,
      count_message_not_seen: 0,
    };

    ChatService.markReadGroupChat(data).then((res) => {
      if (res.status === 200) {
        //handle rebind unred count
        CommonTopicModule.SET_RELOAD_UNREAD(true);
      }
    });
  }

  /**
   * Open modal list liker modal
   */
  openListLikerModal(messageLiker: UserGroupChat[]) {
    this.likerListModal = messageLiker.map((item) => {
      return {
        id: item.user_active_status ? -1 : item.id,
        avatar:
          item.user_active_status === EUserStatus.LEAVE_SYSTEM
            ? ""
            : item.user_profile?.avatar,
        liker_name: item.name,
        user_active_status: item.user_active_status,
      };
    });
    this.$bvModal.show("msg-liker-list");
  }

  handleChatContent(keyCode: string) {
    if (!keyCode) return "";
    const text = keyCode.split(",")[0];
    switch (text) {
      case this.LEAVE_GROUP_CHAT:
        return this.$t("chat.list.msg.leave_group_chat", {
          name: keyCode.split(",")[1],
        });
      case this.LEAVE_SYSTEM:
        return this.$t("chat.list.msg.leave_system", {
          name: keyCode.split(",")[1],
        });
      default:
        return keyCode;
    }
  }

  getFormatIconClass(extension: string) {
    return CheckFileFormat.getIconClassNameWithFileExtention(extension);
  }

  getFileFormat(extension: string) {
    return CheckFileFormat.getFileFormat(extension);
  }

  handleLongText(text: string, max?: number) {
    return PreviewText.covertToPreviewText(text, max);
  }

  async leaveGroupChat() {
    const groupChatId = this.$route.params.groupChatId;
    this.$blockui.show();
    await GroupChatService.leaveGroupChat({
      group_chat_id: groupChatId,
    })
      .then((res) => {
        if (res.status === 200) {
          this.$router.push({
            name: "chat-list",
          });

          this.$bvModal.show("modal-success-leave-chat");
        }
      })
      .catch((err) => console.log(err));
    this.$blockui.hide(false);
  }
}
