import Const from '../constants'
import axiosApiInstance from '@/interceptors'

class MessageService {
  getAllMessageInGroupChat(params: any) {
    let url = `${Const.api_url}message/get/${params.groupChatId}?limit=${params.limit}`
    url = params.messageSearchId ? url + `&find_page_by_message_id=${params.messageSearchId}` : url + `&page=${params.page}`
    return axiosApiInstance.get(url)
  }

  getDetailMessage(messageid: any) {
    return axiosApiInstance.get(Const.api_url + 'message/' + messageid)
  }

  sendMessage(messageNew: any) {
    return axiosApiInstance.post(Const.api_url + 'message/send', messageNew)
  }

  editMessage(messageEdit: any, messageId: any) {
    return axiosApiInstance.put(Const.api_url + 'message/edit/' + messageId, messageEdit)
  }

  reactMessage(msgId: any, data: any) {
    return axiosApiInstance.post(Const.api_url + 'message/react/' + msgId, data)
  }

  updateDataLike(msgId: any, data: any) {
    return axiosApiInstance.post(Const.api_url + 'message/react/' + msgId + '/firebase', data)
  }

  updateDataDelete(msgId: any, data: any) {
    return axiosApiInstance.post(Const.api_url + 'message/react/' + msgId + '/firebase', data)
  }
}

export default new MessageService
